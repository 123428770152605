import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import { Link } from 'gatsby'

import { COLOR, FONT_SIZE, RADIUS, SPACE } from 'Theme'

import { scrollIntoView } from 'utils/scroll'
import { handleize } from 'utils/helpers'

import Button from 'components/Button'
import Rating from 'components/Rating'
import { Box, Flex } from 'components/Layout'

import Star from 'images/svg/star.svg'
import patrikPrescakPhoto from 'images/photos/patrik-prescak.jpg'
import marikaSagiovaPhoto from 'images/photos/marika-sagiova.jpg'
import jozefMaruscakPhoto from 'images/photos/jozef-maruscak.png'

const Photo = styled('img')`
  width: ${rem(43)};
  height: ${rem(43)};
  margin-right: ${SPACE.S};
  border-radius: ${RADIUS.CIRCLE};
`

const StyledRating = styled(Rating)`
  text-align: center;
  font-size: ${FONT_SIZE.S};
`

const StyledStar = styled('img')`
  width: auto;
  height: ${FONT_SIZE.L};
  margin-left: ${SPACE.S};
`

const LinkWithoutUnderLine = styled(Link)`
  text-decoration: none;
`

const SCROLL_OFFSET = 20

const ReviewCTA = ({ buttonTitle, linkTo }) => {
  const handleClickOnButton = () => {
    if (linkTo === '/') {
      setTimeout(() => {
        scrollIntoView({
          name: handleize('Prihlasovací formulár'),
          offset: -SCROLL_OFFSET,
        })
      }, 500)
    }
  }

  return (
    <Flex
      display="flex"
      alignItems="center"
      flexDirection={{ S: 'column', M: 'row' }}
      border={`1px solid ${COLOR.WHITE_75}`}
      pt={{ S: SPACE.L, M: SPACE.XM }}
      pb={{ S: SPACE.L, M: SPACE.M }}
      px={{ S: SPACE.S, M: SPACE.L }}
    >
      <Box width="100%" alignItems="center" justifyContent="center">
        <Flex
          maxWidth="70%"
          margin="0 auto"
          justifyContent="space-evenly"
          paddingBottom={{ S: SPACE.M, M: SPACE.S }}
        >
          <Photo src={marikaSagiovaPhoto} alt="Fotka absolventa kurz" />
          <Box marginTop="-12px">
            <Photo src={patrikPrescakPhoto} alt="Fotka absolventa kurz" />
          </Box>
          <Photo src={jozefMaruscakPhoto} alt="Fotka absolventa kurz" />
        </Flex>
        <StyledRating icon={<StyledStar src={Star} alt="Rating Star" />} />
      </Box>
      <Flex ml={{ S: 0, M: SPACE.XXL }}>
        <Box mt={{ S: SPACE.M, M: 0 }}>
          <LinkWithoutUnderLine to={linkTo}>
            <Button onClick={handleClickOnButton} variant="primary" isFullWidth>
              {buttonTitle}
            </Button>
          </LinkWithoutUnderLine>
        </Box>
      </Flex>
    </Flex>
  )
}

ReviewCTA.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
}

export default ReviewCTA
