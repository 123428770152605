import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import { COLOR, FONT_SIZE, FONT_WEIGHT, SPACE, mq } from 'Theme'
import { Text } from 'components/Typography'
import { Flex } from 'components/Layout'
import linkedInIcon from 'images/svg/linkedin.svg'
import cubeImage from 'images/svg/graphic-cube-white.svg'

const Content = styled('div')`
  isolation: isolate;

  ${mq.from.M`
    max-width: ${rem(574)};
  `}

  padding: ${SPACE.XM};
  margin-left: -${SPACE.XM};

  background-color: ${COLOR.BLACK};
`

const Photo = styled('img')`
  width ${rem(320)};
  height ${rem(320)};

  object-fit: cover;
  object-position: top;

  ${mq.from.M`
    max-width: ${rem(248)};
    max-height: ${rem(324)};
  `}
`

const SocialLink = styled('a')`
  max-width: ${rem(20)};
  max-height: ${rem(20)};
`

const Cube = styled('img')`
  position: absolute;
  top: -${SPACE.L};
  right: 0;

  ${mq.to.M`
    display: none;
  `}
`

const Instructor = ({
  photo,
  name,
  position,
  experience,
  linkedIn,
  linkId,
}) => (
  <Flex
    position="relative"
    alignItems={{ M: 'center' }}
    flexDirection={{ S: 'column', M: 'row' }}
  >
    <Photo src={photo} alt={`Fotka ${name}`} />
    <Cube src={cubeImage} alt="" />
    <Content>
      <Flex
        justifyContent="space-between"
        flexDirection={{ S: 'column', M: 'row' }}
      >
        <div>
          <Text
            as="p"
            fontSize={FONT_SIZE.XXXXL}
            fontWeight={FONT_WEIGHT.MEDIUM}
            lineHeight={1.2}
          >
            {name}
          </Text>
          <Text as="p" fontSize={FONT_SIZE.S} opacity="0.5">
            {position}
          </Text>
        </div>
        <SocialLink
          id={linkId}
          href={linkedIn}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedInIcon} alt="" />
        </SocialLink>
      </Flex>
      <Text as="p" mt={{ S: SPACE.L, M: SPACE.M }}>
        {experience}
      </Text>
    </Content>
  </Flex>
)

Instructor.propTypes = {
  photo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  experience: PropTypes.string.isRequired,
  linkedIn: PropTypes.string.isRequired,
  linkId: PropTypes.string.isRequired,
}

export default Instructor
