import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import {
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  GRADIENT,
  mq,
  RADIUS,
  SPACE,
} from 'Theme'
import { Text } from 'components/Typography'
import { Flex } from 'components/Layout'

const defaultHint = (
  <>
    <p>
      Ide o dočasnú <strong>uvádzaciu cenu</strong>, ktorá platí iba na toto
      kolo kurzov (následné ďalšie kurzy už budú za plnú cenu)
    </p>
    <br />
    <p>
      Platbu za kurz je možné na základe individuálnej dohody{' '}
      <strong>rozdeliť na viacero častí</strong>
    </p>
  </>
)

const PriceText = styled('span')`
  font-family: Telegraf;
  font-size: ${FONT_SIZE.XXXXXL};
`

const Before = styled(PriceText)`
  position: relative;

  margin-left: ${SPACE.XS};
  margin-right: ${SPACE.M};

  color: ${COLOR.SQUANT};

  &::after {
    content: '';
    pointer-events: none;
    user-select: none;

    display: block;
    position: absolute;
    top: calc(50% - 0.1em);
    left: -5%;
    right: -2%;

    border-top: 2px solid ${COLOR.PRIMARY_RED};

    transform: rotate(-7deg);
  }
`

const Message = styled('div')`
  cursor: auto;
  position: absolute;
  z-index: 1;

  width: max-content;
  max-width: ${rem(260)};
  padding: ${SPACE.M};

  font-size: ${FONT_SIZE.XS};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  text-align: center;

  background-color: ${COLOR.BLACK};
  background-image: linear-gradient(${GRADIENT.COURSE_CARD});
  border-radius: ${RADIUS.M};

  transition: 0.4s ease-out;
  transition-property: opacity, transform;

  ${mq.to.M`
    right: calc(100% + ${SPACE.M});
  `}

  // Hover bridge
  &::after {
    content: '';
    position: absolute;
    top: 0;

    height: 100%;
    width: ${SPACE.M};

    ${mq.to.M`
    right: -${SPACE.M};
  `}
  }
`

Message.defaultProps = {
  $direction: 'left',
}

const Hint = styled('div')`
  cursor: help;
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: ${rem(24)};
  height: ${rem(24)};
  margin-left: ${SPACE.S};

  border-radius: ${RADIUS.CIRCLE};
  background-color: ${COLOR.BLACK_OUT};

  &:not(:hover) ${Message} {
    pointer-events: none;
    transform: translateX(-${SPACE.M});

    opacity: 0;
  }
`

const Price = ({ before, after, hint }) => (
  <div>
    <Text fontSize={FONT_SIZE.S} fontWeight={FONT_WEIGHT.MEDIUM}>
      UVÁDZACIA CENA
    </Text>
    <Flex alignItems="center">
      <Before>{before}</Before>
      <PriceText>{after}</PriceText>
      {hint && (
        <Hint>
          <span>?</span>
          <Message>{hint}</Message>
        </Hint>
      )}
    </Flex>
  </div>
)

Price.defaultProps = {
  hint: defaultHint,
}

Price.propTypes = {
  before: PropTypes.string.isRequired,
  after: PropTypes.string.isRequired,
  hint: PropTypes.node,
}

export default Price
