import React, { Fragment } from 'react'
import PropTypes, { string } from 'prop-types'
import styled from 'styled-components'
import { COLOR, FONT_SIZE, FONT_WEIGHT, mq, RADIUS, SPACE } from 'Theme'
import { Grid } from 'components/Layout'
import Line from 'components/Line'
import { rem } from 'polished'

const LeftContent = styled('p')`
  border: 1px solid ${COLOR.WHITE_75};
  text-transform: uppercase;
  font-size: ${FONT_SIZE.M};
  line-height: ${rem(28)};
  text-align: center;
  padding: ${SPACE.M};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};

  ${mq.from.M`
    border: none;
    padding: 0;
    padding-right: 8.5rem;
    text-align: left;
    font-size: ${FONT_SIZE.XXXL};
    line-height: ${SPACE.L};
    margin-top: 4px;
  `}
`

const RightContent = styled('p')`
  text-align: center;
  font-size: ${FONT_SIZE.M};
  line-height: ${SPACE.L};

  ${mq.from.M`
    text-align: left;
  `}
`

const ForWho = styled('div')`
  text-align: center;
  font-size: ${FONT_SIZE.M};
  line-height: ${SPACE.XM};

  ${mq.from.M`
    border: 1px solid ${COLOR.WHITE_50};
    border-radius: ${RADIUS.L};
    font-size: ${FONT_SIZE.M};
    line-height: ${SPACE.L};
    padding: ${SPACE.L};
  `}
`

const ForWhoTitle = styled('h2')`
  font-size: ${FONT_SIZE.XXL};
  line-height: ${SPACE.L};
  margin-bottom: ${SPACE.XM};

  ${mq.from.M`
    font-size: ${FONT_SIZE.M};
    line-height: ${SPACE.XM};
    margin-bottom: ${SPACE.M};
  `}
`

const ForWhoContent = styled('p')`
  font-size: ${FONT_SIZE.M};
  line-height: ${SPACE.XM};
  color: ${COLOR.LIGHT_GRAY};
`

const ForWhoItem = styled('span')`
  white-space: nowrap;
`

const Dot = styled('span')`
  color: ${COLOR.PRIMARY_RED};
`

const CenteredLine = styled(Line)`
  align-self: center;
  ${mq.to.M`
    display: none;
  `}
`

const SubHeroSection = ({
  leftContent,
  rightContent,
  forWhoTitle,
  forWhoContent,
}) => (
  <Grid
    gridTemplateColumns={{ S: '1fr', M: '1.5fr 1fr' }}
    gridRowGap={{ S: rem(40), M: SPACE.XL }}
    mt={{ S: rem(56), M: SPACE.XXL }}
  >
    <LeftContent>{leftContent}</LeftContent>
    <RightContent>{rightContent}</RightContent>
    <CenteredLine />
    <ForWho>
      <ForWhoTitle>{forWhoTitle}</ForWhoTitle>
      <ForWhoContent>
        {forWhoContent.map((forWhoItem, itemIndex) => (
          <Fragment key={forWhoItem}>
            <ForWhoItem>{forWhoItem}</ForWhoItem>
            {itemIndex !== forWhoContent.length - 1 && <Dot> • </Dot>}
          </Fragment>
        ))}
      </ForWhoContent>
    </ForWho>
  </Grid>
)

SubHeroSection.propTypes = {
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  forWhoTitle: PropTypes.string.isRequired,
  forWhoContent: PropTypes.arrayOf(string).isRequired,
}

export default SubHeroSection
