import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import { FONT_WEIGHT, SPACE, mq } from 'Theme'
import { Text } from 'components/Typography'

const StyledNumberList = styled('ol')`
  display: grid;
  gap: ${SPACE.XM};

  padding: ${SPACE.M} 0 ${SPACE.L};

  list-style: none;

  ${mq.from.M`
    grid-template-columns: repeat(2, 1fr);
    gap: ${SPACE.XL} ${SPACE.XM};
  `}
`

const Item = styled('li')`
  position: relative;

  padding: ${SPACE.L} 0;
`

const Number = styled('span')`
  pointer-events: none;
  user-select: none;
  position: absolute;

  font-family: Telegraf;
  font-size: ${rem(200)};
  line-height: 1;
  text-align: center;

  /**
   * Magic numbers follow, it’s hard to position this, but it’s possible now
   * to change the font-size freely
   */
  top: calc(4rem - 0.35em);
  left: -0.15em;
  min-width: 0.75em;

  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;

  opacity: 0.2;

  ${mq.from.M`
    font-size: ${rem(220)};
  `}
`

const NumberList = ({ items }) => (
  <StyledNumberList>
    {items.map(({ title, text }, index) => {
      const ordinal = index + 1
      return (
        <Item key={index}>
          <Number>{ordinal}</Number>
          <Text as="p" fontWeight={FONT_WEIGHT.SEMI_BOLD} my={SPACE.S}>
            {ordinal} - {title}
          </Text>
          <p>{text}</p>
        </Item>
      )
    })}
  </StyledNumberList>
)

NumberList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default NumberList
