import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem, math } from 'polished'
import { COLOR, FONT_SIZE, FONT_WEIGHT, RADIUS, SPACE, mq } from 'Theme'
import { Text } from 'components/Typography'

const NUMBER_SIZE = rem(56)
const stepListMQ = mq.from.M

const StyledNumberList = styled('ol')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  list-style: none;

  ${stepListMQ`
    grid-auto-flow: column;
    grid-template-columns: auto;
  `}
`

const Item = styled('li')`
  position: relative;

  margin: ${SPACE.S} 0;

  ${stepListMQ`
    &:not(:last-child)::after {
      content: '';

      display: block;
      position: absolute;
      top: ${math(`${NUMBER_SIZE} / 2`)};
      left: ${NUMBER_SIZE};
      right: 0;

      border-top: 1px solid ${COLOR.PRIMARY_RED_40};
    }
  `}
`

const Number = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${NUMBER_SIZE};
  width: ${NUMBER_SIZE};

  font-size: ${FONT_SIZE.XXXXL};

  color: ${COLOR.PRIMARY_RED};
  background-color: ${COLOR.PRIMARY_RED_90};
  border-radius: ${RADIUS.CIRCLE};
`

const StepList = ({ items }) => (
  <StyledNumberList>
    {items.map((text, index) => (
      <Item key={index}>
        <Number>{index + 1}</Number>
        <Text
          as="p"
          fontWeight={FONT_WEIGHT.MEDIUM}
          my={SPACE.M}
          maxWidth={rem(160)}
        >
          {text}
        </Text>
      </Item>
    ))}
  </StyledNumberList>
)

StepList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export default StepList
