import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import { GRADIENT, RADIUS, SPACE, mq } from 'Theme'

const StyledIconList = styled('ul')`
  display: grid;
  gap: ${SPACE.L};

  list-style: none;

  ${mq.from.M`
    grid-template-columns: repeat(2, 1fr);
  `}
`

const Icon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${rem(56)};
  height: ${rem(56)};
  margin-bottom: ${SPACE.XM};

  border-radius: ${RADIUS.CIRCLE};
  background: no-repeat center;
  background-image: linear-gradient(${GRADIENT.ICON_TEXT_CIRCLE});

  img {
    max-width: ${rem(27)};
    max-height: ${rem(27)};
  }
`

const IconList = ({ items }) => (
  <StyledIconList>
    {items.map(({ icon, text }, index) => (
      <li key={index}>
        <Icon>
          <img src={icon} alt="" />
        </Icon>
        <span>{text}</span>
      </li>
    ))}
  </StyledIconList>
)

IconList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default IconList
