import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Flex } from 'components/Layout'
import { COLOR, FONT_SIZE, FONT_WEIGHT, mq, SPACE } from 'Theme'
import { Text } from 'components/Typography'

const StyledBox = styled(Box)`
  border-bottom: 0.5px solid ${COLOR.WHITE_75};

  ${mq.from.M`
    border-bottom: unset;
  `}
`

const Title = styled('h4')`
  font-size: ${FONT_SIZE.M};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  text-transform: uppercase;
`

const DescriptionList = ({ content }) => (
  <Flex
    width={{ S: '100%', M: '50%' }}
    flexDirection="column"
    justifyContent="space-between"
  >
    {content.map(({ title, description }) => (
      <StyledBox
        key={title}
        pb={{ S: SPACE.M, M: 0 }}
        mb={{ S: SPACE.XM, M: SPACE.L }}
      >
        <Title>{title}</Title>
        <Text fontSize={FONT_SIZE.M}>{description}</Text>
      </StyledBox>
    ))}
  </Flex>
)

DescriptionList.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default DescriptionList
