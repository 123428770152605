import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { mq } from 'Theme'
import { GUTTER } from 'components/mixins'

const Layout = styled('div')`
  display: grid;

  ${mq.from.M`
    grid-template-columns: 1fr 1fr;
    margin-right: -${GUTTER};
  `}
`

const HeroSection = ({ children }) => <Layout>{children}</Layout>

HeroSection.propTypes = {
  children: PropTypes.node,
}

export default HeroSection
