import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import { COLOR, FONT_SIZE, FONT_WEIGHT, SPACE, mq } from 'Theme'
import { H1, Text } from 'components/Typography'
import { Box, Flex } from 'components/Layout'
import Rating from 'components/Rating'
import Star from 'images/svg/star.svg'

const TOP_OFFSET = {
  MOBILE: rem(80),
  DESKTOP: rem(200),
}

const TITLE_SIZE = {
  MOBILE: rem(40),
  DESKTOP: FONT_SIZE.XXXXXXL,
}

const LINE_HEIGHT = {
  MOBILE: rem(48),
  DESKTOP: rem(62),
}

const MainTitle = styled(H1)`
  font-size: ${TITLE_SIZE.DESKTOP};
  line-height: ${LINE_HEIGHT.DESKTOP};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};

  ${mq.to.M`
    font-size: ${TITLE_SIZE.MOBILE};
    line-height: ${LINE_HEIGHT.MOBILE};
  `}
`

const HeroFlex = styled(Flex)`
  ${mq.to.M`
    justify-content: center;
    text-align: center;
    div {
      padding-bottom: 0;
    }
  `}
`

const SubTitle = styled(Text)`
  ${mq.to.M`
    font-size: ${FONT_SIZE.M}
  `}
`

const Buttons = styled(Box)`
  margin-top: ${SPACE.L};

  > * + * {
    margin-left: ${SPACE.L};
  }

  ${mq.to.L`
    > * + * {
      display: flex;
      margin-top: ${SPACE.M};
      margin-left: 0;
    }
  `}

  ${mq.to.M`
    display: flex;

    > * {
      flex: 1;
      min-width: 0 !important;
    }

    > * + * {
      margin-top: 0;
      margin-left: ${SPACE.M};
    }
  `}
`

const Image = styled('img')`
  flex: 1;
  margin-top: ${SPACE.XXL};
  max-height: ${rem(450)};

  ${mq.to.M`
    display: none;
  `}
`

const StyledRating = styled(Rating)`
  text-align: center;
  font-size: ${FONT_SIZE.S};
  margin: ${SPACE.L} ${SPACE.XL} 0;

  ${mq.from.M`
    text-align: left;
    margin: ${SPACE.L} 0 0;
  `}
`

const StyledStar = styled('img')`
  width: auto;
  height: ${FONT_SIZE.L};
  margin-left: ${SPACE.S};
`

const Hero = ({
  hasImage,
  backgroundImage,
  buttons,
  subtitle,
  title,
  metaInfo,
  ...props
}) => (
  <HeroFlex alignItems={{ S: 'flex-end', L: 'center' }} pb={SPACE.L} {...props}>
    <Box
      pt={{ S: TOP_OFFSET.MOBILE, M: TOP_OFFSET.DESKTOP }}
      pb={{ S: SPACE.M, M: SPACE.L, L: SPACE.XXL }}
      pr={{ M: SPACE.XL }}
      flex={{ S: 'unset', M: '1 50%' }}
    >
      <Text
        as="p"
        fontSize={FONT_SIZE.S}
        color={COLOR.WHITE_30}
        mt={{ S: SPACE.XL, M: SPACE.L }}
        mb={SPACE.M}
      >
        {metaInfo}
      </Text>
      <MainTitle>{title}</MainTitle>
      <SubTitle
        as="p"
        fontSize={FONT_SIZE.L}
        lineHeight={rem(30)}
        color={COLOR.WHITE}
        mt={SPACE.S}
        maxWidth={rem(540)}
      >
        {subtitle}
      </SubTitle>
      <Buttons>{buttons}</Buttons>
      <StyledRating icon={<StyledStar src={Star} alt="Rating Star" />} />
    </Box>
    {hasImage && <Image src={backgroundImage} alt="" />}
  </HeroFlex>
)

Hero.propTypes = {
  hasImage: PropTypes.bool,
  backgroundImage: PropTypes.string,
  buttons: PropTypes.node,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  metaInfo: PropTypes.node,
}

export default Hero
