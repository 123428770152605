import React, { useState } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { FONT_SIZE, SPACE, COLOR, mq } from 'Theme'
import { URL } from 'constants/constants'
import { Text } from 'components/Typography'
import { Flex, Grid } from 'components/Layout'
import gymBeamLogo from 'images/logos/gym-beam.png'
import promiseoLogo from 'images/logos/promiseo.png'
import nordlichtLogo from 'images/logos/nordlicht.png'
import sudolabsLogo from 'images/logos/sudolabs-io.png'
import grownAppsLogo from 'images/logos/grown-apps.png'
import hotovoLogo from 'images/logos/hotovo.png'
import itImpulseLogo from 'images/logos/it-impulse.png'
import uniquePeopleLogo from 'images/logos/unique-people.png'
import gohealthLogo from 'images/logos/gohealth.svg'
import insteaLogo from 'images/logos/instea.png'
import goodRequestLogo from 'images/logos/good-request.png'
import senacorLogo from 'images/logos/senacor.png'
import websupportLogo from 'images/logos/websupport.png'
import vacuumlabsLogo from 'images/logos/vacuumlabs.png'
import { DesktopOnly, MobileOnly } from 'components/ResponsiveDisplay'

const LOGO_HEIGHT = {
  GYM_BEAM: 50,
  PROMISEO: 56,
  NORDLICHT: 74,
  SUDO_LABS: 41,
  GROWN_APPS: 67,
  HOTOVO: 60,
  IT_IMPULSE: 45,
  UNIQUE_PEOPLE: 70,
  GOHEALTH: 30,
  GOODREQUEST: 29,
  SENACOR: 45,
  WEBSUPPORT: 45,
  VACUUMLABS: 35,
  INSTEA: 30,
}

const FiveGrid = styled(Grid)`
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
`

const FourGrid = styled(Grid)`
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  margin: auto;
  width: 80%;
`

const Link = styled('a').attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  display: ${({ isDisplayed }) => (isDisplayed ? 'inline-flex' : 'none')};
  justify-content: center;
  flex: 50%;

  padding: ${SPACE.M};
  margin: ${SPACE.S} 0;

  ${mq.from.M`
    flex: initial;
    padding: initial;
    margin: ${SPACE.L} ${SPACE.M};
  `}

  ${mq.from.L`
    margin: ${SPACE.XM} ${SPACE.XM};
  `}
`

const Image = styled('img')`
  object-fit: contain;

  ${({ height }) =>
    height &&
    `max-height: ${rem(height)};
  `}
`

const StyledButton = styled('button')`
  margin: auto;
`

const LogoGrid = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Flex flexDirection="column" justifyContent="center" {...props}>
      <MobileOnly>
        <Grid alignItems="center" gridTemplateColumns="repeat(3, 1fr)">
          <Link id="logo-grid-websupport" href={URL.WEBSUPPORT} isDisplayed>
            <Image
              src={websupportLogo}
              alt="Websupport logo"
              height={LOGO_HEIGHT.WEBSUPPORT}
            />
          </Link>
          <Link id="logo-grid-sudolabs" href={URL.SUDOLABS} isDisplayed>
            <Image
              src={sudolabsLogo}
              alt="Sudolabs.io logo"
              height={LOGO_HEIGHT.SUDO_LABS}
            />
          </Link>
          <Link id="logo-grid-gymbeam" href={URL.GYMBEAM} isDisplayed>
            <Image
              src={gymBeamLogo}
              alt="GymBeam logo"
              height={LOGO_HEIGHT.GYM_BEAM}
            />
          </Link>
          <Link id="logo-grid-goodrequest" href={URL.GOODREQUEST} isDisplayed>
            <Image
              src={goodRequestLogo}
              alt="Goodrequest logo"
              height={LOGO_HEIGHT.GOODREQUEST}
            />
          </Link>
          <Link id="logo-grid-vacuumlabs" href={URL.VACUUMLABS} isDisplayed>
            <Image
              src={vacuumlabsLogo}
              alt="Promiseo logo"
              height={LOGO_HEIGHT.VACUUMLABS}
            />
          </Link>
          <Link id="logo-grid-hotovo" href={URL.INSTEA} isDisplayed>
            <Image
              src={insteaLogo}
              alt="Instea logo"
              height={LOGO_HEIGHT.INSTEA}
            />
          </Link>
          <Link id="logo-grid-hotovo" href={URL.HOTOVO} isDisplayed>
            <Image
              src={hotovoLogo}
              alt="Hotovo logo"
              height={LOGO_HEIGHT.HOTOVO}
            />
          </Link>
          <Link id="logo-grid-nordlicht" href={URL.NORDLICHT} isDisplayed>
            <Image
              src={nordlichtLogo}
              alt="Nordlicht logo"
              height={LOGO_HEIGHT.NORDLICHT}
            />
          </Link>
          <Link id="logo-grid-gohealth" href={URL.GOHEALTH} isDisplayed>
            <Image
              src={gohealthLogo}
              alt="GoHealth logo"
              height={LOGO_HEIGHT.GOHEALTH}
            />
          </Link>
          <Link id="logo-grid-promiseo" href={URL.PROMISEO} isDisplayed>
            <Image
              src={promiseoLogo}
              alt="Promiseo logo"
              height={LOGO_HEIGHT.PROMISEO}
            />
          </Link>
          <Link
            id="logo-grid-grownapps"
            href={URL.GROWNAPPS}
            isDisplayed={isExpanded}
          >
            <Image
              src={grownAppsLogo}
              alt="Grownapps logo"
              height={LOGO_HEIGHT.GROWN_APPS}
            />
          </Link>
          <Link
            id="logo-grid-unique-people"
            href={URL.UNIQUE_PEOPLE}
            isDisplayed={isExpanded}
          >
            <Image
              src={uniquePeopleLogo}
              alt="Unique People logo"
              height={LOGO_HEIGHT.UNIQUE_PEOPLE}
            />
          </Link>
          <Link
            id="logo-grid-senacor"
            href={URL.SENACOR}
            isDisplayed={isExpanded}
          >
            <Image
              src={senacorLogo}
              alt="IT Impulse logo"
              height={LOGO_HEIGHT.SENACOR}
            />
          </Link>
          <Link
            id="logo-grid-it-impulse"
            href={URL.IT_IMPULSE}
            isDisplayed={isExpanded}
          >
            <Image
              src={itImpulseLogo}
              alt="IT Impulse logo"
              height={LOGO_HEIGHT.IT_IMPULSE}
            />
          </Link>
        </Grid>
      </MobileOnly>
      <DesktopOnly>
        <FiveGrid>
          <Link id="logo-grid-websupport" href={URL.WEBSUPPORT} isDisplayed>
            <Image
              src={websupportLogo}
              alt="Websupport logo"
              height={LOGO_HEIGHT.WEBSUPPORT}
            />
          </Link>
          <Link id="logo-grid-sudolabs" href={URL.SUDOLABS} isDisplayed>
            <Image
              src={sudolabsLogo}
              alt="Sudolabs.io logo"
              height={LOGO_HEIGHT.SUDO_LABS}
            />
          </Link>
          <Link id="logo-grid-gymbeam" href={URL.GYMBEAM} isDisplayed>
            <Image
              src={gymBeamLogo}
              alt="GymBeam logo"
              height={LOGO_HEIGHT.GYM_BEAM}
            />
          </Link>
          <Link id="logo-grid-goodrequest" href={URL.GOODREQUEST} isDisplayed>
            <Image
              src={goodRequestLogo}
              alt="Goodrequest logo"
              height={LOGO_HEIGHT.GOODREQUEST}
            />
          </Link>
          <Link id="logo-grid-vacuumlabs" href={URL.VACUUMLABS} isDisplayed>
            <Image
              src={vacuumlabsLogo}
              alt="Promiseo logo"
              height={LOGO_HEIGHT.VACUUMLABS}
            />
          </Link>
        </FiveGrid>
        <FiveGrid>
          <Link id="logo-grid-hotovo" href={URL.INSTEA} isDisplayed>
            <Image
              src={insteaLogo}
              alt="Instea logo"
              height={LOGO_HEIGHT.INSTEA}
            />
          </Link>
          <Link id="logo-grid-hotovo" href={URL.HOTOVO} isDisplayed>
            <Image
              src={hotovoLogo}
              alt="Hotovo logo"
              height={LOGO_HEIGHT.HOTOVO}
            />
          </Link>
          <Link id="logo-grid-nordlicht" href={URL.NORDLICHT} isDisplayed>
            <Image
              src={nordlichtLogo}
              alt="Nordlicht logo"
              height={LOGO_HEIGHT.NORDLICHT}
            />
          </Link>
          <Link id="logo-grid-gohealth" href={URL.GOHEALTH} isDisplayed>
            <Image
              src={gohealthLogo}
              alt="GoHealth logo"
              height={LOGO_HEIGHT.GOHEALTH}
            />
          </Link>
          <Link id="logo-grid-promiseo" href={URL.PROMISEO} isDisplayed>
            <Image
              src={promiseoLogo}
              alt="Promiseo logo"
              height={LOGO_HEIGHT.PROMISEO}
            />
          </Link>
        </FiveGrid>
        <FourGrid>
          <Link
            id="logo-grid-grownapps"
            href={URL.GROWNAPPS}
            isDisplayed={isExpanded}
          >
            <Image
              src={grownAppsLogo}
              alt="Grownapps logo"
              height={LOGO_HEIGHT.GROWN_APPS}
            />
          </Link>
          <Link
            id="logo-grid-unique-people"
            href={URL.UNIQUE_PEOPLE}
            isDisplayed={isExpanded}
          >
            <Image
              src={uniquePeopleLogo}
              alt="Unique People logo"
              height={LOGO_HEIGHT.UNIQUE_PEOPLE}
            />
          </Link>
          <Link
            id="logo-grid-senacor"
            href={URL.SENACOR}
            isDisplayed={isExpanded}
          >
            <Image
              src={senacorLogo}
              alt="IT Impulse logo"
              height={LOGO_HEIGHT.SENACOR}
            />
          </Link>
          <Link
            id="logo-grid-it-impulse"
            href={URL.IT_IMPULSE}
            isDisplayed={isExpanded}
          >
            <Image
              src={itImpulseLogo}
              alt="IT Impulse logo"
              height={LOGO_HEIGHT.IT_IMPULSE}
            />
          </Link>
        </FourGrid>
      </DesktopOnly>
      {!isExpanded && (
        <StyledButton
          onClick={() => {
            setIsExpanded((prevIsExpanded) => !prevIsExpanded)
          }}
        >
          <Text
            as="p"
            textAlign="center"
            fontSize={FONT_SIZE.M}
            color={COLOR.WHITE_50}
            pt={{ S: SPACE.L, M: SPACE.XL }}
          >
            a ďalších...
          </Text>
        </StyledButton>
      )}
    </Flex>
  )
}

export default LogoGrid
