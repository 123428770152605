import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'components/Layout'
import styled from 'styled-components'
import { COLOR, FONT_SIZE, FONT_WEIGHT, SPACE } from 'Theme'
import { Text } from 'components/Typography'

const Title = styled('h4')`
  font-size: ${FONT_SIZE.M};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  text-transform: uppercase;
`

const Dot = styled('span')`
  color: ${COLOR.PRIMARY_RED};
  margin-right: ${SPACE.S};

  &:after {
    content: '•';
  }
`

const StyledText = styled('p')`
  white-space: nowrap;
  margin-bottom: ${SPACE.S};
`
const DotList = ({ title, items }) => (
  <Box pr={{ S: 0, M: SPACE.L }}>
    <Title>{title}</Title>
    <Box>
      {items.map((item) => (
        <StyledText key={item}>
          <Dot />
          <Text fontSize={FONT_SIZE.M}>{item}</Text>
        </StyledText>
      ))}
    </Box>
  </Box>
)

DotList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export default DotList
