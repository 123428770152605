import React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'
import { COLOR, FONT_SIZE, FONT_WEIGHT, mq, SPACE } from 'Theme'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import { Link } from 'gatsby'

const Title = styled('h2')`
  font-size: ${FONT_SIZE.XXL};
  line-height: ${SPACE.L};

  ${mq.from.M`
    font-size: ${FONT_SIZE.XXXXXXL};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `}
`

const Subtitle = styled('p')`
  font-size: ${FONT_SIZE.XL};
  line-height: ${SPACE.L};
  width: 18rem;
  margin: auto;
`

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

// TODO add link to reviews
const Banner = ({ title, subtitle, linkText }) => (
  <Box
    bg={COLOR.PRIMARY_RED_20}
    py={SPACE.XL}
    px={SPACE.L}
    textAlign="center"
    mx={{ S: `-${SPACE.L}`, L: '0' }}
  >
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    <Button variant="banner" mt={SPACE.L}>
      <StyledLink to="/reviews">{linkText}</StyledLink>
    </Button>
  </Box>
)

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
}

export default Banner
